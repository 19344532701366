import React from "react"

interface IBackdropFilterProps {
    onFilterClicked:()=>void,
    isBlurred: boolean
}

class BackdropFilter extends React.Component<IBackdropFilterProps>{
    constructor(props: any) {
        super(props)
    }

    onFilterClicked() {
        this.props.onFilterClicked()
    }

    render() {
        let className = 'c-backdrop-filter'

        if (this.props.isBlurred) {
            className += ' blurred'
        }

        return (
            <div className={className} onClick={() => this.onFilterClicked()}>
                {this.props.children}
                <div className="c-backdrop-filter__overlay"></div>
            </div>
        )
    }
}

export default BackdropFilter