import React from "react"

import Header from "../components/header";
import BackdropFilter from "../components/backdrop-filter";
import Helmet from "react-helmet";

interface IDefaultPageProps {
    isIndex: boolean,
    isNewsPost?: boolean,
    isShowLetterbox: boolean,
    letterboxItem: any,
    onLetterboxClose: () => void
}

class DefaultPage extends React.Component<IDefaultPageProps, {}> {
    constructor(props: any) {
        super(props)

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', () => this.closeMobileMenu())
            window.addEventListener('scroll', () => this.onScroll())
        }
    }

    state = {
        isMobileNavOpen: false,
        isScrolling: false,
        isHeaderBlurred: false,
        isBackdropBlurred: false
    }

    toggleMobileMenu() {
        this.setState({ isMobileNavOpen: !this.state.isMobileNavOpen })
    }

    closeMobileMenu() {
        this.setState({ isMobileNavOpen: false })
    }

    onScroll() {
        const isScrolling = typeof window !== 'undefined' && window.scrollY > 0
        this.setState({ isScrolling: isScrolling })
    }

    getLetterbox() {
        let letterboxClassName = 'c-letterbox'

        if (this.props.isShowLetterbox) {
            letterboxClassName += ' active'
        }

        return (
            <div className={letterboxClassName}>
                <div className="c-letterbox__backdrop" onClick={() => this.props.onLetterboxClose()}></div>
                <div className="c-letterbox__content-container">
                    {this.props.letterboxItem}
                    <a className="c-letterbox__close" onClick={() => this.props.onLetterboxClose()}>Close</a>
                </div>
            </div>
        )
    }

    render() {
        const isHeaderBlurred = this.props.isShowLetterbox
        const isBackdropBlurred = this.props.isShowLetterbox

        const className = this.props.isShowLetterbox ? 'letterbox-active' : ''

        if (typeof document !== 'undefined' && document && document.documentElement && document.documentElement.classList) {
            if (this.props.isShowLetterbox) {
                document.documentElement.classList.add('letterbox-active');
            } else {
                document.documentElement.classList.remove('letterbox-active');
            }
        }

        return (
            <div className="c-app-container">
                <Helmet>
                    <body className={className}></body>
                </Helmet>
                <Header isIndex={this.props.isIndex} isNewsPost={this.props.isNewsPost} isMobileNavOpen={this.state.isMobileNavOpen} isScrolling={this.state.isScrolling} 
                    onCloseMobileMenu={() => this.closeMobileMenu()} onToggleMobileMenu={() => this.toggleMobileMenu()}
                    isBlurred={isHeaderBlurred} />
    
                <BackdropFilter onFilterClicked={() => this.closeMobileMenu()} isBlurred={isBackdropBlurred}>
                    {this.props.children}
                </BackdropFilter>

                {this.getLetterbox()}
            </div>
        )
    }
}

export default DefaultPage;
