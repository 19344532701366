import React from "react";
import { duskGamesLink } from "../const";

const Footer = () => (
    <footer className="c-footer">
        <aside>
            <a className="c-dusk-link" 
                href={duskGamesLink}>Dusk Games</a>
            <p>&copy; 2021 Dusk Games</p>
            <p>Created and developed by Dusk Games</p>
        </aside>
        <nav>
            <a className="c-footer__page-link" href="/privacy-policy" title="Privacy">Privacy</a>
            {/* <a className="c-footer__page-link" href="/terms-of-use" title="Terms of Use">Terms of Use</a> */}
        </nav>
    </footer>
)

export default Footer