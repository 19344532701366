import React from "react"
import Helmet from "react-helmet";

import { graphql } from "gatsby"

import DefaultPage from "../templates/default-page";
import Footer from "../components/footer";

class Template extends React.Component<StandalonePageQuery, {}> {
    constructor(props: any) {
        super(props)
    }

    state = {
        isShowLetterbox: false,
        letterboxItem: [] as any,
    }

    showPhoto(item: any) {
        this.setState({ isShowLetterbox: true, letterboxItem: item })
    }

    onLetterboxClose() {
        this.setState({ isShowLetterbox: false, letterboxItem: null })
    }

    render() {

        const { markdownRemark } = this.props.data // data.markdownRemark holds our post data
        const { frontmatter, html } = markdownRemark
            
        return (
            <DefaultPage isIndex={false} isShowLetterbox={this.state.isShowLetterbox} onLetterboxClose={() => this.onLetterboxClose()} letterboxItem={this.state.letterboxItem}>
                <Helmet>
                    <title>Frostfall - {frontmatter.linkLabel}</title>
                    <meta name="description" content={`Frostfall - ${frontmatter.linkLabel}`} />
                </Helmet>

                <div className="c-news-post-container">
                    <div className="news-post">
                        <h2>{frontmatter.linkLabel}</h2>
                        <h4>{frontmatter.date}</h4>
                        <div
                        className="news-post-content"
                        dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>
                </div>
                <Footer />
            </DefaultPage>
        )
    }
}


export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        linkLabel
      }
    }
  }
`

export default Template