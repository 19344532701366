import React from "react"
import { Link } from "gatsby"

import {appStoreLink, duskGamesLink} from '../const'

interface IHeaderProps {
    isNewsPost?: boolean,
    isIndex?: boolean,
    isMobileNavOpen: boolean,
    isScrolling: boolean,
    isBlurred: boolean,
    onCloseMobileMenu: () => void,
    onToggleMobileMenu: () => void
}

class Header extends React.Component<IHeaderProps>{
    constructor(props: any) {
        super(props)
    }

    componentDidMount() {
    }

    render() {
        let headerClass = 'c-header container-fluid';
        
        headerClass += this.props.isMobileNavOpen ? ' open' : '';
        headerClass += this.props.isScrolling ? ' scrolling' : '';
        headerClass += this.props.isBlurred ? ' blurred' : '';
        headerClass += this.props.isIndex ? '' : ' inner';

        const homeLink = this.props.isIndex ? '#' : '/#';
        const newsLink = this.props.isIndex ? '#news' : '/#news';
        const mediaLink = this.props.isIndex ? '#media' : '/#media';
        const socialLink = this.props.isIndex ? '#social' : '/#social';
        const downloadLink = this.props.isIndex || this.props.isNewsPost ? '#download' : '/#download';

        return (
            <header className={headerClass}>
                <div className="row">
                    <div className="c-header__logo-container col-lg-3 col-6">
                        <Link className="c-header__logo js-menu-link" 
                            to={homeLink} 
                            onClick={() => this.props.onCloseMobileMenu()}>Frostfall</Link>
                    </div>
                    {/* <div className="c-header__menu-container col-6">
                        <a className="c-header__menu js-menu" onClick={() => this.props.onToggleMobileMenu()}>Menu</a>
                    </div> */}
                    <nav className="c-header__nav col-lg-9">
                        {/* <div className="c-header__nav-container">
                            <Link className="c-header__link js-menu-link"
                                to={newsLink}
                                title="News"
                                onClick={() => this.props.onCloseMobileMenu()}>
                                <span className="c-header__link-text">News</span>
                            </Link>
                            <Link className="c-header__link js-menu-link"
                                to={mediaLink}
                                title="Media"
                                onClick={() => this.props.onCloseMobileMenu()}>
                                <span className="c-header__link-text">Media</span>
                            </Link>
                            <Link className="c-header__link js-menu-link o-button"
                                to={socialLink}
                                title="Social"
                                onClick={() => this.props.onCloseMobileMenu()}>
                                <span className="c-header__link-text">Social</span>
                            </Link>
                        </div> */}
                        <footer className="c-header__nav-footer">
                            <a className="c-dusk-link" href={duskGamesLink}>Dusk Games</a>
                            {/* <a className="c-app-store-link" href={appStoreLink}>Download on the App Store</a> */}
                        </footer>
                    </nav>
                </div>
            </header>
        )
    }
}

export default Header